import { render } from "./appInfo.vue?vue&type=template&id=573a5328"
import script from "./appInfo.vue?vue&type=script&lang=js"
export * from "./appInfo.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "573a5328"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('573a5328', script)) {
    api.reload('573a5328', script)
  }
  
  module.hot.accept("./appInfo.vue?vue&type=template&id=573a5328", () => {
    api.rerender('573a5328', render)
  })

}

script.__file = "src/page/appInfo/appInfo.vue"

export default script