<template>
  <section id="tutoringContent">
    <!-- content -->
    <article>
      <div class="sub-visual sub-visual--leveltest">
        <div class="sub-visual__wrapper">
          <h2 class="sub-visual__title">
            튜터링 원어민
            <br />
            1:1 무료 회화 체험
          </h2>
          <p class="sub-visual__desc">
            전세계 1000명의 튜터와 1:1 영어 수업!
            <br />
            5단계 검증 된 전문 튜터를 만나보세요.
          </p>
          <a href="https://itunes.apple.com/kr/app/id1115973003" class="sub-visual__button btnDownloadApp">
            앱 설치하고 무료 체험하기
          </a>
        </div>
      </div>

      <div class="item_g item_merit">
        <div class="inner_g">
          <p class="txt_g">
            750명 이상의 튜터가 24시간 상시 대기,
            <br class="m" />
            언제든 수업을 시작할 수 있습니다.
          </p>
          <div class="inner_g">
            <div class="slide_box">
              <swiper
                class="tutors-carousel"
                :pagination="{ clickable: true }"
                :loop="true"
                :slidesPerView="3"
                :centeredSlides="true"
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }"
              >
                <swiper-slide v-for="(img, index) in swiperImg" :key="index">
                  <strong class="tit_merit">
                    <img :src="img" class="img_g" :alt="swiperCts[index]" />
                  </strong>
                  <p class="screen_out">{{ swiperCts[index] }}</p>
                </swiper-slide>
                <div class="btn_swiper swiper-button-next"><span class="ico_swiper"></span></div>
                <div class="btn_swiper swiper-button-prev"><span class="ico_swiper"></span></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>

      <!-- content -->
      <section class="sub_con tt_cmd_con conArea">
        <!-- 텝, 검색 -->
        <article class="tt_top_search inner">
          <h2>튜터</h2>
        </article>

        <!-- 튜터 목록 -->
        <article class="tutor_lst_area inner">
          <h3 class="sound_only">튜터 목록</h3>
          <ul class="tutor_lst">
            <li class="item">
              <a href="https://tutoring.co.kr/home/tutor/kwv57mdpqp">
                <div class="t_box">
                  <p class="thum">
                    <img
                      src="https://cdn.tutoring.co.kr/uploads/prof_img/2021/03/01/20210301143503_603c7d074495a_M.png"
                    />
                  </p>
                  <p class="name">Cheska</p>
                  <p class="txt">부드러운 미소가 아름다운 Francesca 튜터~</p>
                </div>
                <div class="b_box">
                  <p class="lessons">
                    <span>6,010</span>
                    <i>Lessons</i>
                  </p>
                  <p class="type">Global</p>
                </div>
              </a>
            </li>
            <li class="item">
              <a href="https://tutoring.co.kr/home/tutor/mqz2g57yq6">
                <div class="t_box">
                  <p class="thum">
                    <img
                      src="https://cdn.tutoring.co.kr/uploads/prof_img/2021/05/10/20210510140633_6098bf5965a9e_M.jpg"
                    />
                  </p>
                  <p class="name">Joanna R.</p>
                  <p class="txt">런던에서 연극 및 보컬트레이너로 활동한 튜터 조애나!</p>
                </div>
                <div class="b_box">
                  <p class="lessons">
                    <span>3,484</span>
                    <i>Lessons</i>
                  </p>
                  <p class="type">Native</p>
                </div>
              </a>
            </li>
            <li class="item">
              <a href="https://tutoring.co.kr/home/tutor/ewdp1r6xwv">
                <div class="t_box">
                  <p class="thum">
                    <img src="https://cdn.tutoring.co.kr/uploads/prof_img/2019/12/23/20191223142209_5e004f01a1121_M" />
                  </p>
                  <p class="name">Samantha</p>
                  <p class="txt">따뜻하고 친근하고, 한국에서 영어강사로 일한 튜터 사만사!</p>
                </div>
                <div class="b_box">
                  <p class="lessons">
                    <span>3,325</span>
                    <i>Lessons</i>
                  </p>
                  <p class="type">Native</p>
                </div>
              </a>
            </li>
            <li class="item">
              <a href="https://tutoring.co.kr/home/tutor/83kx9nl1wn">
                <div class="t_box">
                  <p class="thum">
                    <img src="https://cdn.tutoring.co.kr/uploads/prof_img/2019/02/01/20190201183602_5c54130211a01_M" />
                  </p>
                  <p class="name">Marielle</p>
                  <p class="txt">편하게 수업을 진행해주는 튜터, 긴장감 제로!</p>
                </div>
                <div class="b_box">
                  <p class="lessons">
                    <span>2,173</span>
                    <i>Lessons</i>
                  </p>
                  <p class="type">Global</p>
                </div>
              </a>
            </li>
            <li class="item">
              <a href="https://tutoring.co.kr/home/tutor/kwjm2y62w9">
                <div class="t_box">
                  <p class="thum">
                    <img src="https://cdn.tutoring.co.kr/uploads/prof_img/2020/01/08/20200108051559_5e14e6ff7dca7_M" />
                  </p>
                  <p class="name">Tristan</p>
                  <p class="txt">시간 가는줄 모르게 몰입도있는 영국튜터 트리스턴!</p>
                </div>
                <div class="b_box">
                  <p class="lessons">
                    <span>1,792</span>
                    <i>Lessons</i>
                  </p>
                  <p class="type">Native</p>
                </div>
              </a>
            </li>
            <li class="item">
              <a href="https://tutoring.co.kr/home/tutor/eqord9jzqd">
                <div class="t_box">
                  <p class="thum">
                    <img src="https://cdn.tutoring.co.kr/uploads/prof_img/2020/03/04/20200304143456_5e5f3e00c1a1e_M" />
                  </p>
                  <p class="name">Pauline</p>
                  <p class="txt">친절한 폴린 튜터와 함께하는 영어수업!</p>
                </div>
                <div class="b_box">
                  <p class="lessons">
                    <span>1,626</span>
                    <i>Lessons</i>
                  </p>
                  <p class="type">Global</p>
                </div>
              </a>
            </li>
            <li class="item">
              <a href="https://tutoring.co.kr/home/tutor/9w9mze52qv">
                <div class="t_box">
                  <p class="thum">
                    <img src="https://cdn.tutoring.co.kr/uploads/prof_img/2019/11/12/20191112152320_5dca4fd8367b1_M" />
                  </p>
                  <p class="name">Farrari</p>
                  <p class="txt">친근하고 재미있는 튜터 Farrari :)</p>
                </div>
                <div class="b_box">
                  <p class="lessons">
                    <span>375</span>
                    <i>Lessons</i>
                  </p>
                  <p class="type">Native</p>
                </div>
              </a>
            </li>
          </ul>
          <a href="https://tutoring.co.kr/home/tutors" class="sub-section__button">더 많은 튜터 만나러 가기</a>
        </article>
      </section>
      <!-- //content -->
    </article>
    <!-- // content -->
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'AppInfo',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperImg: [
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_01.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_02.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_03.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/tutor_card_04.png',
      ],
      swiperCts: [
        '지금, 이순간도 튜터 대기 중',
        '가자? 음악감독? 튜터!',
        '완전한 개인지도, 1:1 피드백',
        '내게 꼭 맞는 엄선된 튜터',
      ],
    };
  },
};
</script>
